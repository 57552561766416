import { $events } from '../../helpers/events';
import { isElement, preventDefault } from '../../helpers/_utilities';
import { $dom } from '../../helpers/dom';
import variables from '../../variables';
import axios from 'axios';
import { $data } from '../../helpers/data';
// import { Modal } from 'bootstrap';
// import { Modal } from 'bootstrap';

const {
  get,
  getAll,
  each,
  attr,
  removeClass,
  addClass,
  hasClass,
  toggleClass,
  html,
  exist
} = $dom;

const {
  lazyLoading,
  error
} = variables.classNames;

export default function modalAuthorize(
  modalId = 'modal--authorization',
  navSelector = '.js-modal--authorization-nav',
  messageOutputSelector = '#modal--authorization-message',
  formsDataAttr = 'data-auth-form',
  url = `${variables.rootUrl}/auth/`
) {

  const fieldSelector = '.js-form-field';
  const errorFieldSelector = '.js-field-error';

  let forms = null;
  let isFormValid = false;


  const checkField = form => {
    const fields = getAll('input', form);

    isFormValid = true;
    let passwordArr = [];

    fields.forEach(element => {

      const fieldType = attr(element, 'data-fieldtype');

      if (fieldType == 'password') {

        passwordArr.push(element);

        if (element.value == '') {

          addClass(element, 'is-invalid');
          addClass(element.parentNode.parentNode, 'is-invalid');
          get(errorFieldSelector, element.parentNode.parentNode).innerHTML = 'Пожалуйста заполните поле';
          isFormValid = false;
        } else {
          removeClass(element, 'is-invalid');
          removeClass(element.parentNode.parentNode, 'is-invalid');
        }

        if (passwordArr.length > 1) {
          if (element.value == '' || passwordArr[0].value != passwordArr[1].value) {
            get(errorFieldSelector, passwordArr[0].parentNode.parentNode).innerHTML = 'Поля должны быть одинаковыми';
            get(errorFieldSelector, passwordArr[1].parentNode.parentNode).innerHTML = 'Поля должны быть одинаковыми';
            addClass(passwordArr[0], 'is-invalid');
            addClass(passwordArr[1], 'is-invalid');
            addClass(passwordArr[0].parentNode.parentNode, 'is-invalid');
            addClass(passwordArr[1].parentNode.parentNode, 'is-invalid');
            isFormValid = false;
          } else {
            removeClass(passwordArr[0], 'is-invalid');
            removeClass(passwordArr[1], 'is-invalid');
            removeClass(passwordArr[0].parentNode.parentNode, 'is-invalid');
            removeClass(passwordArr[1].parentNode.parentNode, 'is-invalid');
          }
        }
      }

      if (fieldType == 'email') {
        const re = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;

        if (re.test(element.value)) {
          removeClass(element, 'is-invalid');
          removeClass(element.parentNode, 'is-invalid');
        } else {
          addClass(element, 'is-invalid');
          addClass(element.parentNode, 'is-invalid');
          get(errorFieldSelector, element.parentNode).innerHTML = 'Почта введена неверно';
          isFormValid = false;
        }
      }
    });

    // isFormValid = true;

    fields.forEach(element => {
      if (hasClass(element, 'is-invalid')) {
        isFormValid = false;
      }
    });
  }

  const
    requestAuth = form => {
      if (isElement(form)) {

        const submitButton = get('button[type="submit"]', form);

        // const fields = getAll('input', form);

        // console.log(fields);

        checkField(form);
        // console.log(isFormValid);

        // fields.forEach(element => {
        //   if (checkField(element)) {
        //     console.log('ok');
        //   } else {
        //     console.log('not ok');
        //     return;
        //   }
        // });

        // console.log(isFormValid);

        if (isFormValid) {
          console.log('Форма готова к отправке');
          // addClass(submitButton, lazyLoading); -- функция вешает лоадер на кнопку
          // removeClass(submitButton, lazyLoading);; -- функция убирает лоадер с кнопки

          // axios({
          //   method: 'POST',
          //   url,
          //   data: new FormData(form)
          // })
          //   .then(({ data }) => {
          //     const { result, text, ref } = data;
          //     if (result) {
          //       if (Boolean(ref)) window.location = ref
          //     } else {
          //       html(messageOutputSelector, text);
          //       addClass(messageOutputSelector, error);
          //     }
          //   })
          //   .finally(() => {
          //     removeClass(submitButton, lazyLoading);
          //   })
        } else {
          console.log('Форма не готова к отправке');
        }
      }
    },
    handleSubmit = event => {
      event.preventDefault();
      requestAuth(event.target.closest(`[${formsDataAttr}]`));
    },
    toggleForm = arg => {

      let btn = null;

      if (arg instanceof Event) {
        preventDefault(arg);
        btn = arg.target.closest(navSelector);
      } else if (isElement(arg)) {
        btn = arg;
      }

      removeClass(navSelector, variables.classNames.active);

      if (isElement(btn)) {
        each(forms, form => {
          form.style.display = attr(form, formsDataAttr) === attr(btn, 'href') ? 'block' : 'none';
        });
        addClass(btn, variables.classNames.active)
      }
    },
    init = () => {

      if (exist('#' + modalId)) {
        forms = getAll(`[${formsDataAttr}]`, '#' + modalId);
        const activeNav = getAll(navSelector).find(nav => hasClass(nav, variables.classNames.active));

        if (isElement(activeNav)) toggleForm(activeNav);

        $events.delegate
          .on('click tap', navSelector, toggleForm)
          .on('click tap', '.js-modal-open-auth', event => {
            const target = event.target.closest('.js-modal-open-auth');

            const href = attr(target, 'data-modal-tab');

            const tabsRoot = getAll('.js-modal--authorization-nav', '#modal--authorization');

            tabsRoot.forEach(element => {
              if (attr(element, 'href') == href) {
                element.click();
              } else {
              }
            })
          })
          .on('click tap', '.js-password-toggler', (event) => {
            const target = event.target;
            // console.log(target.parentNode.parentNode);

            const passwordField = get('input', target.parentNode.parentNode);

            console.log(passwordField);

            const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';
            passwordField.setAttribute('type', type);
            toggleClass(target.parentNode, 'is-active');
            // toggle the eye slash icon
            // this.classList.toggle('fa-eye-slash');
          })
          .on('submit', `[${formsDataAttr}]`, handleSubmit)
        // .on('input', `[${formsDataAttr}] ${fieldSelector}`, (event) => {
        //   checkField(event.target)
        // })
      }

    },
    destroy = () => {
      forms = null;

      $events.delegate
        .off('click tap', navSelector, toggleForm)
        .off('click tap', '.js-modal-open-auth', event => {
          const target = event.target.closest('.js-modal-open-auth');

          const href = attr(target, 'data-modal-tab');

          const tabsRoot = getAll('.js-modal--authorization-nav', '#modal--authorization');

          tabsRoot.forEach(element => {
            if (attr(element, 'href') == href) {
              element.click();
            } else {
            }
          })
        })
        .off('click tap', '.js-password-toggler', (event) => {
          const target = event.target;
          // console.log(target.parentNode.parentNode);

          const passwordField = get('input', target.parentNode.parentNode);

          console.log(passwordField);

          const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';
          passwordField.setAttribute('type', type);
          toggleClass(target.parentNode, 'is-active');
          // toggle the eye slash icon
          // this.classList.toggle('fa-eye-slash');
        })
        .off('submit', `[${formsDataAttr}]`, handleSubmit)
    }
    ;

  return {
    init, destroy
  }
}