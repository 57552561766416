import {$dom} from '../../../helpers/dom';
import {$events} from '../../../helpers/events';
import gsap from 'gsap';
import variables from '../../../variables';
import is from 'is_js';
import {
  addZero, debounce, differenceBetweenArrays, encodeUrl, forIn,
  getCaretPosition, getData,
  isElement, loop, objHasProp,
  preventDefault,
  setInputFilter, toMinAndSec, warn
} from '../../../helpers/_utilities';
import Model from '../../classes/Model';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import {catalogFilterChangeObserver, catalogRenderObserver, searchBadgeDeleteObserver} from '../../observers';
import {$data} from '../../../helpers/data';
import filterParams from './fiterParams';
import filterUrlManager from "./filterUrlManager";


const {
  get: domGet,
  getAll: domGetAll,
  attr,
  removeClass,
  addClass,
  callAll,
  hasClass,
  exist,
  each
} = $dom;

function decimals(numbers, mark = '.') {
  return wNumb({decimals: numbers, mark})
}

const paramsManager = filterParams();

export default class CatalogFilter extends Model {

  constructor(options = {}) {
    super(options);

    this.defaults = {
      formSelector: '.js-catalog-page-filter',
      controlsPanelSelector: '.js-catalog-filter--controls',
      personalPanelSelector: '.js-catalog-filter--personal',
      settingsPanelSelector: '.js-catalog-filter--settings',
      checkboxSelector: '.js-catalog-filter--checkbox input[type=checkbox]',
      radioSelector: '.js-catalog-filter--radio input[type=radio]',
      tabsItemSelector: '.js-catalog-filter--item',
      rangeSelector: '.js-catalog-filter--range',
      bpmOutputFromSelector: '.js-catalog-filter--range-output--from',
      bpmOutputToSelector: '.js-catalog-filter--range-output--to',

      lengthRootSelector: '.js-catalog-filter--length',
      lengthInputSelector: '.js-catalog-filter--length-field',

      buttonSelector: '.js-catalog-filter-button',

      involvedControlClassName: 'is-involved',
      settingsPanelShift: -20,
      apiUrl: variables.xhrRootUrl+'getFilterData'
    };

    this.options = Object.assign(this.defaults, options);

    this.controlsPanel = null;
    this.settingsPanel = null;
    this.checkboxTypeName = 'checkbox';
    this.radioTypeName = 'radio';
    this.rangeTypeName = 'range';
    this.lengthTypeName = 'length';

    this.openSettingsPanelTriggerAttr = 'data-filter-button';
    this.openSettingsPanelTriggerSelector = `[${this.openSettingsPanelTriggerAttr}]`;

    this.tabsItemValueAttr = 'data-filter-item';
    this.tabsItemSelector = `[${this.tabsItemValueAttr}]`;

    this.tabsItemTypeAttr = 'data-filter-type';
    this.tabsItemTypeCheckboxSelector = `[${this.tabsItemTypeAttr}="${this.checkboxTypeName}"]`;
    this.tabsItemTypeRadioSelector = `[${this.tabsItemTypeAttr}="${this.radioTypeName}"]`;

    this.tabs = [];
    this.settingsPanelIsOpen = false;
    this.currentTab = null;
    this.tabsData = {};

    this.rangeInputRoleAttr = 'data-value-role';
    this.rangeInputTypeAttr = 'data-value-type';

    this.pageInstance = null;

    this.bpmRangeSlider = null;
    this.ApiFilterData = null;

    const $CatalogFilter = this;

    this.params = {
      fieldNamePrefix: 'catalog-filter-',
      keys: {
        bpmMin: 'bpm_min',
        bpmMax: 'bpm_max',
        lengthMin: 'length_min',
        lengthMax: 'length_max'
      },
      get formData() {
        return $data.serialize(new FormData($CatalogFilter.rootEl))
      },
      replacePrefix: val => val.replace(this.params.fieldNamePrefix, ''),
      updateFormFromSavedData: () => {
        const savedFormData = paramsManager.formData.get();
        const {bpmMin, bpmMax, lengthMin, lengthMax} = this.params.keys;

        const processLength = (role, value) => {
          const inputsGroup = domGetAll(`${this.options.lengthInputSelector}[${this.rangeInputRoleAttr}="${role}"]`);

          const {
            minutes,
            seconds
          } = toMinAndSec(value);

          each(inputsGroup, input => {
            switch (attr(input, this.rangeInputTypeAttr)) {
              case 'minutes':
                input.value = addZero(minutes);
                break;
              case 'seconds':
                input.value = addZero(seconds);
                break;
            }
          })
        };

        for (let key in savedFormData) {
          if (savedFormData.hasOwnProperty(key)) {

            const value = savedFormData[key];

            switch (this.params.replacePrefix(key)) {
              case bpmMin:
                this.bpmRangeSlider.set([Number(value), null]);
                break;
              case bpmMax:
                this.bpmRangeSlider.set([null, Number(value)]);
                break;
              case lengthMin:
                processLength('from', value);
                break;
              case lengthMax:
                processLength('to', value);
                break;
              default:
                const setInputChecked = (input, val) => {
                  if (attr(input, 'value') === val) input.checked = true;
                };

                each(domGetAll(`[name="${key}"]`, this.rootEl), input => {
                  switch (input.getAttribute('type')) {
                    case 'checkbox':
                    case 'radio':
                      if (is.array(value)) {
                        loop(value, val => setInputChecked(input, val))
                      } else if (is.string(value)) {
                        setInputChecked(input, value);
                      }
                      break;
                  }
                });
            }
          }
        }

        callAll(this.options.tabsItemSelector, this.checkInvolvedFilters);
        this.params.update();
      },
      reset: () => {
        window.history.replaceState(
          {},
          '',
          this.pageInstance.router.navigo.getCurrentLocation().url
        );

        paramsManager.formData.save({});
      },
      update: () => {
        const
          {keys, formData} = this.params,
          {navigo} = this.pageInstance.router
        ;

        let filteredFormDataToSave = {};

        const
          executeDataPair = fieldName => ({
            value: formData[fieldName],
            key: this.params.replacePrefix(fieldName)
          }),
          processTiming = (condition, fieldName) => {
            let {key, value} = executeDataPair(fieldName);

            if (is.array(value) && value.length === 2)
              value = (parseInt(value[0]) * 60 + parseInt(value[1])).toString();

            if (condition && Number(value) !== 0) {

              filterUrlManager.updateHistoryState(key, value);
              filteredFormDataToSave[fieldName] = value;
            } else {
              filterUrlManager.removeHistoryKey(key)
            }
          }
        ;

        if (is.empty(formData)) {
          this.params.reset();
        } else {
          for (let fieldName in formData) {
            if (formData.hasOwnProperty(fieldName)) {

              const
                {key, value} = executeDataPair(fieldName),
                isBpmKey = key === keys.bpmMin || key === keys.bpmMax,
                isLengthKey = key === keys.lengthMin || key === keys.lengthMax
              ;

              if (isBpmKey) {
                const
                  [rangeStartMin, rangeStartMax] = this.bpmRangeSlider.options.start,
                  [rangeValMin, rangeValMax] = this.bpmRangeSlider.get()
                ;

                processTiming(
                  (parseInt(rangeValMin) !== rangeStartMin || parseInt(rangeValMax) !== rangeStartMax),
                  fieldName
                )

              } else if (isLengthKey) {
                const {from, to} = this.prettifyLength();

                if (Boolean(this.ApiFilterData)) {
                  processTiming(
                    (from !== this.ApiFilterData[keys.lengthMin] || to !== this.ApiFilterData[keys.lengthMax]),
                    fieldName
                  )
                }

              } else {
                filterUrlManager.updateHistoryState(key, value);
                filteredFormDataToSave[fieldName] = value;
              }
            }
          }
        }

        if (is.not.null(navigo)) {

          const {params} = navigo.getCurrentLocation();

          if (is.not.null(params)) {

            const
              dataKeys =
                Object.keys(filteredFormDataToSave)
                  .map(this.params.replacePrefix),
              paramsKeys = Object.keys(params)
            ;

            if (paramsKeys.length > dataKeys.length) {
              loop(
                differenceBetweenArrays(paramsKeys, dataKeys),
                filterUrlManager.removeHistoryKey.bind(filterUrlManager)
              )
            }
          }

          paramsManager.save(navigo.getCurrentLocation().params)

        } else {
          warn('Navigo router is not ready, can\'t update params from url', 'CatalogFilter, params.update')
        }

        paramsManager.formData.save(filteredFormDataToSave);
      }
    };

    this.openSettingsPanelHandler = this.openSettingsPanelHandler.bind(this);
    this.hideSettingsPanelByClickOutside = this.hideSettingsPanelByClickOutside.bind(this);
    this.checkInvolvedFilters = this.checkInvolvedFilters.bind(this);
    this.onSearchBadgeDelete = this.onSearchBadgeDelete.bind(this);
    this.onCatalogRender = this.onCatalogRender.bind(this);
    this.checkParamsHistoryByInit = this.checkParamsHistoryByInit.bind(this);
    this.reset = this.reset.bind(this);
    this.params.update = this.params.update.bind(this);
  }

  _toTime(val) {
    return `${Math.floor(val/60)}:${('0' + Math.floor(val%60)).slice(-2)}`
  }

  bpmRange(action) {

    const
      $filter = this,
      rangeEl = domGet(this.options.rangeSelector, this.rootEl)
    ;
    if (isElement(rangeEl)) {

      const
        print = (el, val) => {
          el[el.tagName.toLowerCase() === 'input' ? 'value' : 'innerHTML'] = Math.round(val)
        },
        printGroup = (group, val) => {
          each(group, el => print(el, val));
        }
      ;

      function onRangeChange() {
        $filter.checkInvolvedFilters(this.target.closest($filter.options.tabsItemSelector));
        $filter.params.update()
      }
      function onRangeUpdate(values, handle, unencoded) {
        const
          rangeEl = this.target,
          rangeParent = rangeEl.closest('.js-catalog-filter--range-parent'),
          outputsFrom = domGetAll($filter.options.bpmOutputFromSelector, rangeParent),
          outputsTo = domGetAll($filter.options.bpmOutputToSelector, rangeParent)
        ;

        printGroup(outputsFrom, unencoded[0]);
        printGroup(outputsTo, unencoded[1]);
      }

      switch (action) {
        case 'init':

          const start = rangeEl.dataset.start.split(',').map(el => +el);

          this.bpmRangeSlider = noUiSlider.create(rangeEl, {
            start,
            connect: true,
            margin: 30,
            tooltips: true,
            step: + rangeEl.dataset.step,
            range: JSON.parse(rangeEl.dataset.range),
            format: wNumb({
              decimals: 0,
              thousand: ' '
            })
          });

          rangeEl.noUiSlider.on('update', onRangeUpdate);
          rangeEl.noUiSlider.on('change', onRangeChange);

          break;

        case 'destroy':
          rangeEl.noUiSlider.off('update', onRangeUpdate);
          rangeEl.noUiSlider.off('change', onRangeChange);
          rangeEl.noUiSlider.destroy();
          this.bpmRangeSlider = null;
          break;
      }
    }

    return rangeEl
  }

  lengthRange(action) {

    const {
        lengthRootSelector,
        lengthInputSelector
      } = this.options,
      rangeInputsFilter = setInputFilter(lengthInputSelector, /^\s*\d*\s*$/)
    ;

    const
      valueIsValid = value => value !== '' && is.number(Number(value)),
      executeDataFromField = field => {

        const {
            value,
            dataset
          } = field,
          dataMax = Number(dataset.max),
          dataMin = Number(dataset.min),
          {valueRole, valueType} = dataset
        ;

        return {
          value, dataMax, dataMin, valueRole, valueType
        }
      },
      addValueZero = field => {
        if (valueIsValid(field.value)) field.value = addZero(field.value)
      },
      setMinMaxValues = field => {

        const {
          value, dataMax, dataMin
        } = executeDataFromField(field);


        if (valueIsValid(field.value)) {
          if (Number(value) > dataMax) field.value = addZero(dataMax);
          if (Number(value) < dataMin) field.value = addZero(dataMin);
        }

        return {value, dataMin, dataMax}
      },
      calculateTotalTime = fields => {
        return fields.reduce((acc, field) => {
          const val = Number(field.value);

          return acc + (attr(field, this.rangeInputTypeAttr) === 'minutes' ? val * 60 : val);
        }, 0)
      },
      checkEmptyField = field => {
        if (isElement(field) && !valueIsValid(field.value)) {
          field.value = 0;
          setMinMaxValues(field);
          addValueZero(field);
        }
      },
      checkEveryTypesFieldsGroup = (fields, type) => fields.every(f => attr(f, this.rangeInputRoleAttr) === type),
      updateFieldsLimits = (currentFields, oppositeFields) => {

        const currentVal = calculateTotalTime(currentFields);
        const oppositeVal = calculateTotalTime(oppositeFields);

        const [currentMinutes, currentSeconds] = currentFields;
        const [oppositeMinutes, oppositeSeconds] = oppositeFields;

        const isFromType = checkEveryTypesFieldsGroup(currentFields, 'from');
        const isToType = checkEveryTypesFieldsGroup(currentFields, 'to');

        const
          targetOM = Math.floor(currentVal / 60),
          targetOS = currentVal % 60
        ;

        if (isFromType) {
          if (oppositeVal < currentVal) {
            console.log('Значение группы полей to меньше чем значение from');
            attr(oppositeMinutes, 'data-min', targetOM.toString());
            attr(oppositeSeconds, 'data-min', targetOS.toString());

            if (oppositeMinutes.value !== '') {
              oppositeMinutes.value = targetOM.toString();
              addValueZero(oppositeMinutes);
            }

            if (oppositeSeconds.value !== '') {
              oppositeSeconds.value = targetOS.toString();
              addValueZero(oppositeSeconds);
            }
          }
        }

        if (isToType) {

        }
      },
      handleInputChange = ({target}) => {

        addValueZero(target);
        setMinMaxValues(target);

        const
          {valueRole} = executeDataFromField(target),
          oppositeValueRole = valueRole === 'from' ? 'to' : 'from'
        ;

        const
          currentFieldsGroup = domGetAll(`${lengthInputSelector}[${this.rangeInputRoleAttr}=${valueRole}]`),
          oppositeFieldsGroup = domGetAll(`${lengthInputSelector}[${this.rangeInputRoleAttr}=${oppositeValueRole}]`)
        ;

        each(currentFieldsGroup, checkEmptyField);

        updateFieldsLimits(currentFieldsGroup, oppositeFieldsGroup);
      },
      handleInputFocus = function() {
        this.setSelectionRange(0, this.value.length);
      },
      handleInputKeyup = ({target, keyCode}) => {
        let
          isLeftArrow = keyCode === 37,
          isRightArrow = keyCode === 39
        ;

        if (isLeftArrow || isRightArrow) {

          const
            currentFieldsGroup = domGetAll(`${lengthInputSelector}[${this.rangeInputRoleAttr}=${attr(target, this.rangeInputRoleAttr)}]`),
            neighbor = currentFieldsGroup.find(f => f !== target),
            caretPosition = getCaretPosition(target),
            caretTargetPosition = attr(target, this.rangeInputTypeAttr) === 'seconds' ? 0: 2
          ;

          if (caretPosition === caretTargetPosition) neighbor.focus();
        }
      }
    ;

    switch (action) {
      case 'init':
        rangeInputsFilter.init();


        callAll(lengthInputSelector, input => {
          setMinMaxValues(input);
          addValueZero(input);
        });

        $events
          .add('change', lengthInputSelector, handleInputChange)
          .add('focus', lengthInputSelector, handleInputFocus)
          .delegate
          .on('keyup', lengthInputSelector, handleInputKeyup)
        ;

        break;
      case 'destroy':
        rangeInputsFilter.destroy();

        $events
          .remove('change', lengthInputSelector, handleInputChange)
          .remove('focus', lengthInputSelector, handleInputFocus)
          .delegate
          .off('keyup', lengthInputSelector, handleInputKeyup)
        ;
        break;
    }

    return domGet(lengthRootSelector, this.rootEl)
  }

  prettifyLength() {
    const
      rootSelector = `${this.options.lengthInputSelector}`,
      fromRootSelector = `${rootSelector}[${this.rangeInputRoleAttr}=from]`,
      toRootSelector = `${rootSelector}[${this.rangeInputRoleAttr}=to]`,

      fromMinutes = domGet(`${fromRootSelector}[${this.rangeInputTypeAttr}=minutes]`),
      fromSeconds = domGet(`${fromRootSelector}[${this.rangeInputTypeAttr}=seconds]`),

      toMinutes = domGet(`${toRootSelector}[${this.rangeInputTypeAttr}=minutes]`),
      toSeconds = domGet(`${toRootSelector}[${this.rangeInputTypeAttr}=seconds]`)
    ;

    return {
      from: Number(fromMinutes.value) * 60 + Number(fromSeconds.value),
      to: Number(toMinutes.value) * 60 + Number(toSeconds.value),
    }
  }

  requestFilterData() {
    return new Promise((resolve, reject) => {
      getData(this.options.apiUrl)
        .then(resolve)
        .catch(reject)
    })
  }

  updateTimeInputsParamsFromApi(data, bpmEl, lengthEl) {
    const {bpm_min, bpm_max, length_min, length_max} = data;

    if (isElement(bpmEl)) {

      const rangeObj = {
        'min': bpm_min,
        'max': bpm_max
      };

      bpmEl.noUiSlider.updateOptions({
        start: [bpm_min, bpm_max],
        range: rangeObj
      });

      bpmEl.noUiSlider.options.start = [bpm_min, bpm_max];

      bpmEl.dataset.range = JSON.stringify(rangeObj);
      bpmEl.dataset.start = `${bpm_min},${bpm_max}`;
    }

    if (isElement(lengthEl)) {
      const
        roles = ['from', 'to'],
        {
          minutes: minMinutes,
          seconds: minSeconds
        } = toMinAndSec(length_min),
        {
          minutes: maxMinutes,
          seconds: maxSeconds
        } = toMinAndSec(length_max)
      ;

      loop(roles, role => {
        callAll(`[${this.rangeInputRoleAttr}="${role}"]`, input => {
          switch (attr(input, this.rangeInputTypeAttr)) {
            case 'minutes':
              input.dataset.max = maxMinutes.toString();
              input.dataset.min = minMinutes.toString();
              switch (role) {
                case 'from':
                  input.value = addZero(minMinutes);
                  break;
                case 'to':
                  input.value = addZero(maxMinutes);
                  break;
              }
              break;
            case 'seconds':
              input.dataset.max = maxSeconds.toString();
              switch (role) {
                case 'from':
                  input.dataset.min = minSeconds.toString();
                  input.value = addZero(minSeconds);
                  break;
                case 'to':
                  input.value = addZero(maxSeconds);
                  break;
              }
              break
          }
        }, lengthEl);
      })
    }

    loop([bpmEl, lengthEl], el => {
      isElement(el) && this.checkInvolvedFilters(el.closest(this.tabsItemSelector))
    })
  }

  toggleSettingsPanel(action, immediately = false) {
    let classListMethod, autoAlpha, x, duration, eventMethod;

    switch (action) {
      case 'show':
        classListMethod = 'addClass';
        autoAlpha = 1;
        x = 0;
        duration = variables.gsapDefaultDuration;
        eventMethod = 'on';
        break;

      case 'hide':
        classListMethod = 'removeClass';
        autoAlpha = 0;
        x = this.options.settingsPanelShift;
        duration = immediately ? 0 : variables.gsapDefaultDuration;
        eventMethod = 'off';

        removeClass(this.openSettingsPanelTriggerSelector, variables.classNames.active);

        break;
    }

    $dom[classListMethod](this.rootEl, variables.classNames.active);

    gsap.to(this.settingsPanel, {
      autoAlpha, x, duration,
      onComplete: () => {
        $events.delegate[eventMethod]('click tap', document, this.hideSettingsPanelByClickOutside);
      }
    });

    this.settingsPanelIsOpen = action === 'show';

    return this
  }

  showTab(valueId = '') {

    if (is.string(valueId) && valueId.length > 0) {

      const activeTab = domGet(`[${this.tabsItemValueAttr}="${valueId}"]`);

      const otherTabs = this.tabs.filter(tab => {
        return tab !== activeTab
      });

      gsap.set(otherTabs, {autoAlpha: 0});
      removeClass(otherTabs, variables.classNames.active);

      gsap.to(activeTab, {
        autoAlpha: 1,
        duration: variables.gsapDefaultDuration
      });

      addClass(activeTab, variables.classNames.active);

      this.currentTab = activeTab;
    } else {
      this.currentTab = null;
    }

    return this;
  }

  hideTabs() {
    gsap.to(this.tabs, {
      autoAlpha: 0,
      duration: variables.gsapDefaultDuration
    });
    this.currentTab = null;
    return this;
  }

  hideSettingsPanelByClickOutside(event) {
    const isClickInside =
      this.rootEl.contains(event.target)
    ;

    if (!isClickInside) this.toggleSettingsPanel('hide');
  }

  openSettingsPanelHandler(event) {
    preventDefault(event);

    const {
      openSettingsPanelTriggerSelector: buttonSelector,
      openSettingsPanelTriggerAttr: buttonAttr
    } = this;

    const
      currentButton = event.target.closest(buttonSelector),
      btnAttr = attr(currentButton, buttonAttr),
      targetTabSelector = `[${this.tabsItemValueAttr}="${btnAttr}"]`
    ;

    removeClass(buttonSelector, variables.classNames.active);

    const currentTabAttr = attr(
      this.currentTab,
      this.tabsItemValueAttr
    );

    if(this.settingsPanelIsOpen) {
      if (currentTabAttr !== btnAttr && exist(targetTabSelector)) {
        this.showTab(btnAttr);
        addClass(currentButton, variables.classNames.active);
      } else {
        this.toggleSettingsPanel('hide');
        this.hideTabs();
      }
    } else {
      this.currentTab = domGet(targetTabSelector);

      if (isElement(this.currentTab)) {
        this.toggleSettingsPanel('show');
        this.showTab(btnAttr);
        addClass(currentButton, variables.classNames.active);
      }
    }
  }

  checkInvolvedFilters(arg) {

    if (!Boolean(arg) && !isElement(arg)) return false;

    let currentTab, isInvolved = false, targetItemsSelector = null;

    if (isElement(arg)) {
      currentTab = arg
    } else {
      currentTab = arg.target.closest(this.tabsItemSelector);
    }

    if (attr(currentTab, this.tabsItemTypeAttr) === 'players') return;

    const
      currentTabValueAttr = attr(currentTab, this.tabsItemValueAttr),
      currentButton = domGet(`[${this.openSettingsPanelTriggerAttr}="${currentTabValueAttr}"]`),
      currentTabTypeAttr = attr(currentTab, this.tabsItemTypeAttr),

      rangeVal = (el, i, parse = true) => {
        let val = el.hasOwnProperty('noUiSlider') ? el.noUiSlider.get()[i] : '';

        if (parse)
          return parseFloat(val);

        return val
      },
      checkRangeInvolved = el => {
        const rangeDataAttr = JSON.parse(el.dataset.range);

        return rangeDataAttr['min'] !== rangeVal(el,0) || rangeDataAttr['max'] !== rangeVal(el,1);
      },
      getLengthEdge = role => {
        const fieldsGroup = domGetAll(`${this.options.lengthInputSelector}[${this.rangeInputRoleAttr}="${role}"]`);

        return fieldsGroup.reduce((acc, item) => {
          switch (attr(item, this.rangeInputTypeAttr)) {
            case 'minutes':
              acc += parseInt(item.dataset[role === 'from' ? 'min' : 'max']) * 60;
              break;
            case 'seconds':
              acc += parseInt(item.dataset[role === 'from' ? 'min' : 'max']);
              break;
          }

          return acc;
        }, 0);
      },
      checkLengthInvolved = () => {
        const {from, to} = this.prettifyLength();
        return {
          result: getLengthEdge('from') !== from || getLengthEdge('to') !== to,
          from, to
        }
      },
      checkInvolved = (selector, filterFn) => {
        isInvolved = domGetAll(selector, currentTab).filter(filterFn).length > 0;
        targetItemsSelector = selector;
      }
    ;


    switch (currentTabTypeAttr) {
      case this.checkboxTypeName:
        checkInvolved(this.options.checkboxSelector, checkbox => checkbox.checked);
        break;

      case this.rangeTypeName:
        checkInvolved(this.options.rangeSelector, checkRangeInvolved);
        break;

      case this.radioTypeName:
        checkInvolved(this.options.radioSelector, radio => radio.checked);
        break;

      case this.lengthTypeName:
        isInvolved = checkLengthInvolved().result;
        targetItemsSelector = this.options.lengthRootSelector;
        break;
    }

    isInvolved
      ? addClass(currentButton, this.options.involvedControlClassName)
      : removeClass(currentButton, this.options.involvedControlClassName)
    ;

    if (is.not.null(targetItemsSelector) && is.string(targetItemsSelector)) {

      if(!this.tabsData.hasOwnProperty(currentTabValueAttr)) {
        this.tabsData[currentTabValueAttr] = {};
        this.tabsData[currentTabValueAttr]['type'] = currentTabTypeAttr;
      }

      this.tabsData[currentTabValueAttr].items =
        domGetAll(targetItemsSelector, currentTab).map(element => {

          const tabType = this.tabsData[currentTabValueAttr].type;

          let involved = false, value = null, content = null;

          if (tabType === this.checkboxTypeName) {
            involved = element.checked;
            value = element.value || attr(element, 'name');

            content = value;
          }

          if (tabType === this.rangeTypeName) {
            const formatter = decimals(element.dataset.tooltipDecimals, element.dataset.mark);
            let fromVal = formatter.to(rangeVal(element, 0));
            let toVal = formatter.to(rangeVal(element, 1));

            value = {fromVal, toVal};

            content = (element.dataset.searchBadgePrefix || '') + ' ' + `${fromVal}-${toVal}`;

            involved = checkRangeInvolved(element);
          }

          if (tabType === this.radioTypeName) {
            involved = element.checked;
            value = element.dataset.publicName;
            content = (element.dataset.searchBadgePrefix || '') + ' ' + value;
          }

          if (tabType === this.lengthTypeName) {
            const {result, from: fromVal, to: toVal} = checkLengthInvolved();
            involved = result;
            value = {fromVal, toVal};
            content =
              (element.dataset.searchBadgePrefix || '') + ' ' + `${this._toTime(fromVal)}-${this._toTime(toVal)}`;
          }

          const selectorByNameAttr = `[name*=${this.params.fieldNamePrefix}]`;

          let nameAttr;

          if (element.matches(selectorByNameAttr)) {
            nameAttr = attr(element, 'name')
          } else {
            nameAttr = attr(domGet(selectorByNameAttr, element), 'name')
          }

          return {
            element,
            involved,
            content,
            filter: {
              elId: attr(element, variables.dataAttributes.filterElId),
              key: this.params.replacePrefix(nameAttr),
              value
            }
          }
        });

      this.tabsData[currentTabValueAttr].involved =
        this.tabsData[currentTabValueAttr].items.some(item => item.involved);


      catalogFilterChangeObserver.dispatch({
        tabData: this.tabsData[currentTabValueAttr],
        CatalogFilter: this
      });
    }
  }

  onSearchBadgeDelete(badge) {
    const {
      filter
    } = badge;

    const
      filterEl = domGet(`${this.options.tabsItemSelector} [${variables.dataAttributes.filterElId}="${filter.elId}"]`),
      currentTab = filterEl.closest(this.options.tabsItemSelector)
    ;

    if (isElement(currentTab)) {
      switch (attr(currentTab, this.tabsItemTypeAttr)) {
        case this.checkboxTypeName:
          filterEl.checked = false;
          break;

        case this.rangeTypeName:
          const rangeDataAttr = JSON.parse(filterEl.dataset.range);
          filterEl.noUiSlider.set([rangeDataAttr['min'], rangeDataAttr['max']]);
          break;

        case this.radioTypeName:
          filterEl.checked = false;
          break;

        case this.lengthTypeName:
          callAll(`${this.options.lengthInputSelector}[${this.rangeInputRoleAttr}]`, field => {
            field.value = addZero(
              attr(field, this.rangeInputRoleAttr) === 'from'
                ? field.dataset.min
                : field.dataset.max
            )
          });
          break;
      }
    }

    this.checkInvolvedFilters(currentTab);
    this.params.update();
  }

  onCatalogRender() {
    let t = setTimeout(() => {
      this.params.update();
      clearTimeout(t);
    }, 0)
  }

  checkParamsHistoryByInit() {
    const {mainNavs, navigo} = this.pageInstance.router;

    if (!mainNavs.find(nav => navigo.matchLocation(nav)))
      navigo.navigate('/'+mainNavs[0]);
  }

  reset() {
    this.params.reset();
    callAll(this.options.tabsItemSelector, this.checkInvolvedFilters);
  }

  listeners(action) {
    switch (action) {
      case 'add':
        $events.delegate
          .on('click tap', this.openSettingsPanelTriggerSelector, this.openSettingsPanelHandler)
          .on('change', this.options.formSelector, this.checkInvolvedFilters)
          .on('change', this.options.formSelector, this.params.update)
          .on('click tap', `${this.options.buttonSelector}[type="reset"]`, this.reset)
        ;

        searchBadgeDeleteObserver.subscribe(this.onSearchBadgeDelete);
        catalogRenderObserver.subscribe(this.onCatalogRender);
        break;

      case 'remove':
        $events.delegate
          .off('click tap', this.openSettingsPanelTriggerSelector, this.openSettingsPanelHandler)
          .off('change', this.options.formSelector, this.checkInvolvedFilters)
          .off('change', this.options.formSelector, this.params.update)
        ;

        searchBadgeDeleteObserver.unsubscribe(this.onSearchBadgeDelete);
        break;
    }
  }

  init(catalogPage) {
    this.pageInstance = catalogPage;
    this.controlsPanel = domGet(this.options.controlsPanelSelector);
    this.settingsPanel = domGet(this.options.settingsPanelSelector);
    this.tabs = domGetAll(this.options.tabsItemSelector);

    this
      .toggleSettingsPanel('hide', true)
    ;

    const
      bpmRangeEl = this.bpmRange('init'),
      lengthRangeEl = this.lengthRange('init')
    ;

    this.checkParamsHistoryByInit();

    this.requestFilterData()
      .then(data => {

        this.ApiFilterData = data;

        this.updateTimeInputsParamsFromApi(
          this.ApiFilterData,
          bpmRangeEl,
          lengthRangeEl
        );
      })

      .finally(this.params.updateFormFromSavedData);


    this.listeners('add');

    super.init(this);
  }

  destroy() {
    this.pageInstance = null;
    this.ApiFilterData = null;
    this
      .toggleSettingsPanel('hide', true)
      .hideTabs()
    ;

    this.bpmRange('destroy');
    this.lengthRange('destroy');

    this.tabsData = {
      filterInstance: this,
      tabs: []
    };
    this.listeners('add');
    super.destroy(this);
  }
}
